<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowModalAddForm"
          >
            New Request
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowModalViewForm(row.item)"
            >
              View
            </b-button>

            <b-button
              v-if="row.item.request_status.toLowerCase() === 'pending'"
              size="sm"
              class="mr-1"
              variant="danger"
              @click="onShowModalCancelForm(row.item)"
            >
              Cancel
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-payment-request-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmFormPostSubmit"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          novalidate
          role="form"
        >
          <b-card
            no-body
            class="mb-5"
          >
            <b-tabs>
              <b-tab
                title="Request Details"
                active
              >
                <b-form-group v-if="viewMode">
                  <label for="created_at">Request Date</label>
                  <b-input
                    id="created_at"
                    v-model="paymentRequest.created_at"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <ValidationProvider
                  #default="{ errors }"
                  name="request payee"
                  vid="request_payee"
                  rules="required|max:150"
                >
                  <b-form-group>
                    <label for="request_payee">Request Payee</label>
                    <b-input
                      id="request_payee"
                      v-model="paymentRequest.request_payee"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter request payee"
                      autocomplete="off"
                      :disabled="isBusy || viewMode"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="request amount"
                  vid="request_amount"
                  rules="required"
                >
                  <b-form-group>
                    <label for="amount">Request Amount</label>
                    <b-input
                      id="request_amount"
                      v-model="paymentRequest.request_amount"
                      v-mask="currencyMask"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter request amount"
                      autocomplete="off"
                      :disabled="isBusy || viewMode"
                      @blur="paymentRequest.request_amount=numberFormatter(paymentRequest.request_amount)"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <b-form-group v-if="viewMode">
                  <label for="request_status">Request Status</label>
                  <b-input
                    id="request_status"
                    v-model="paymentRequest.request_status"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <ValidationProvider
                  #default="{ errors }"
                  name="budget"
                  vid="budget_id"
                  rules="required"
                >
                  <b-form-group>
                    <label for="budget_name">Budget</label>
                    <v-select
                      id="actual"
                      v-model="selectedBudget"
                      type="text"
                      :options="budgets"
                      label="budget_name"
                      :disabled="isBusy || viewMode"
                    >
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="text-danger small"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="budget actual"
                  vid="actual"
                  rules="required"
                >
                  <b-form-group>
                    <label for="actual">Budget Actual</label>
                    <v-select
                      id="actual"
                      v-model="selectedBudgetActual"
                      type="text"
                      :options="budgetActuals"
                      label="actual_name"
                      :disabled="isBusy || viewMode"
                    >
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="text-danger small"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="item description"
                  vid="item_description"
                  rules="required"
                >
                  <b-form-group>
                    <label for="item_description">Item Description</label>
                    <v-select
                      id="item_description"
                      v-model="selectedItemDescription"
                      type="text"
                      :options="itemDescriptions"
                      label="item_name"
                      :disabled="isBusy || viewMode || selectedBudget === null"
                    >
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="text-danger small"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="request description"
                  vid="request_description"
                  rules="required|max:500"
                >
                  <b-form-group>
                    <label for="request_description">Request Description</label>
                    <b-form-textarea
                      id="request_description"
                      v-model="paymentRequest.request_description"
                      rows="6"
                      max-rows="6"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter request description"
                      autocomplete="off"
                      :disabled="isBusy || viewMode"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="payment mode"
                  vid="payment_mode"
                  rules="required"
                >
                  <b-form-group>
                    <label for="payment_mode">Payment Mode</label>
                    <b-form-select
                      id="payment_mode"
                      v-model="paymentRequest.payment_mode"
                      :options="paymentModes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || viewMode"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Payment Mode --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.payment_mode === 'Check' || paymentRequest.payment_mode === 'Cash'"
                  #default="{ errors }"
                  name="payment action"
                  vid="payment_action"
                  rules="required"
                >
                  <b-form-group>
                    <label for="payment_action">Payment Action</label>
                    <b-form-select
                      id="payment_action"
                      v-model="paymentRequest.payment_action"
                      :options="paymentActions"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || viewMode"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Payment Action --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'"
                  #default="{ errors }"
                  name="bank account number"
                  vid="bank_account_number"
                  rules="required"
                >
                  <b-form-group>
                    <label for="bank_account_number">Bank Account Number</label>
                    <b-input
                      id="bank_account_number"
                      v-model="paymentRequest.bank_account_number"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter bank account number"
                      autocomplete="off"
                      :disabled="isBusy || viewMode"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'"
                  #default="{ errors }"
                  name="bank account name"
                  vid="bank_account_name"
                  rules="required"
                >
                  <b-form-group>
                    <label for="bank_account_name">Bank Account Name</label>
                    <b-input
                      id="bank_account_name"
                      v-model="paymentRequest.bank_account_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter bank account name"
                      autocomplete="off"
                      :disabled="isBusy || viewMode"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'"
                  #default="{ errors }"
                  name="bank"
                  vid="bank"
                  rules="required"
                >
                  <b-form-group>
                    <label for="bank">Bank</label>
                    <b-input
                      id="bank"
                      v-model="paymentRequest.bank"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter bank"
                      autocomplete="off"
                      :disabled="isBusy || viewMode"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <!-- <div
                  v-if="!viewMode"
                  class="form-group mt-3"
                >
                  <b-form-checkbox
                    id="has_noted_by"
                    v-model="paymentRequest.has_approval_one"
                    name="has_noted_by"
                    value="1"
                    unchecked-value="0"
                    :disabled="isBusy"
                  >
                    This request requires Noted By
                  </b-form-checkbox>
                </div> -->

                <ValidationProvider
                  v-if="(!viewMode && parseInt(paymentRequest.has_approval_one) === 1 && user.is_manager === 0) || (viewMode && (selectedNotedBy.id || null) !== null)"
                  #default="{ errors }"
                  name="noted by"
                  vid="approval_one_id"
                  :rules="notedByRequired"
                >
                  <b-form-group>
                    <label for="noted_by">Noted By</label>
                    <v-select
                      id="noted_by"
                      v-model="selectedNotedBy"
                      type="text"
                      :options="employees"
                      label="employee_name"
                      :disabled="isBusy || viewMode"
                    >
                      <template #option="{ employee_name, position_name }">
                        <strong>{{ employee_name }}</strong><br>
                        <span>{{ position_name }}</span>
                      </template>
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="text-danger small"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <b-form-group v-if="viewMode && paymentRequest.approval_one_status !== 'Pending' && paymentRequest.approval_one_name !== null">
                  <label for="approval_one_at">Noted At</label>
                  <b-input
                    id="approval_one_at"
                    v-model="paymentRequest.approval_one_at"
                    :disabled="true"
                    type="text"
                  />
                </b-form-group>

                <b-form-group v-if="viewMode && paymentRequest.approval_one_status !== 'Pending' && paymentRequest.approval_one_name !== null">
                  <label for="approval_one_remarks">Noted By Remarks</label>
                  <b-textarea
                    id="approval_one_remarks"
                    v-model="paymentRequest.approval_one_remarks"
                    rows="6"
                    :disabled="true"
                  />
                </b-form-group>

                <!-- <ValidationProvider
                  v-if="(!viewMode && parseInt(paymentRequest.has_approval_two) === 1) || (viewMode && (selectedApprovedBy.id || null) !== null)"
                  #default="{ errors }"
                  name="approved by"
                  vid="approval_two_id"
                  rules="required"
                >
                  <b-form-group>
                    <label for="approved_by">Approved By</label>
                    <v-select
                      id="approved_by"
                      v-model="selectedApprovedBy"
                      type="text"
                      :options="employees"
                      label="employee_name"
                      :disabled="isBusy || viewMode"
                    >
                      <template #option="{ employee_name, position_name }">
                        <strong>{{ employee_name }}</strong><br>
                        <span>{{ position_name }}</span>
                      </template>
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="text-danger small"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <b-form-group v-if="viewMode && paymentRequest.approval_two_status !== 'Pending' && paymentRequest.approval_two_name !== null">
                  <label for="approval_two_at">Approved At</label>
                  <b-input
                    id="approval_two_at"
                    v-model="paymentRequest.approval_two_at"
                    :disabled="true"
                    type="text"
                  />
                </b-form-group>

                <b-form-group v-if="viewMode && paymentRequest.approval_two_status !== 'Pending' && paymentRequest.approval_two_name !== null">
                  <label for="approval_two_remarks">Approved By Remarks</label>
                  <b-textarea
                    id="approval_two_remarks"
                    v-model="paymentRequest.approval_two_remarks"
                    rows="6"
                    :disabled="true"
                  />
                </b-form-group> -->
              </b-tab>

              <!-- <b-tab
                title="Tax Details"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="transaction type"
                  vid="transaction_type"
                  rules="required"
                >
                  <b-form-group>
                    <label for="transaction_type">Transaction Type</label>
                    <b-form-select
                      id="transaction_type"
                      v-model="paymentRequest.transaction_type"
                      :options="requestTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || viewMode"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Request Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="payment nature"
                  vid="payment_nature"
                  rules="required"
                >
                  <b-form-group>
                    <label for="payment_nature">Payment Nature</label>
                    <b-form-select
                      id="payment_nature"
                      v-model="paymentRequest.payment_nature"
                      :options="paymentNatures"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || viewMode"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Payment Nature --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="document type"
                  vid="document_type"
                  rules="required"
                >
                  <b-form-group>
                    <label for="document_type">Document Type</label>
                    <b-form-select
                      id="document_type"
                      v-model="paymentRequest.document_type"
                      :options="documentTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || viewMode"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Document Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="receipt type"
                  vid="receipt_type"
                  rules="required"
                >
                  <b-form-group>
                    <label for="receipt_type">Receipt Type</label>
                    <b-form-select
                      id="receipt_type"
                      v-model="paymentRequest.receipt_type"
                      :options="receiptTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || viewMode"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Receipt Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <div
                  v-if="!viewMode && paymentRequest.transaction_type === 'With 2307'"
                  class="form-group"
                >
                  <b-form-checkbox
                    id="allow_input"
                    v-model="paymentRequest.supplier_manual_input"
                    name="allow_input"
                    value="1"
                    unchecked-value="0"
                    :disabled="isBusy || viewMode"
                  >
                    Allow me to manually input supplier details
                  </b-form-checkbox>
                </div>

                <ValidationProvider
                  #default="{ errors }"
                  name="supplier trade name"
                  vid="trade_name"
                  rules=""
                >
                  <b-form-group v-if="!viewMode && (parseInt(paymentRequest.supplier_manual_input) === 0 && paymentRequest.transaction_type === 'With 2307')">
                    <label for="trade_name">Supplier Trade Name <small v-if="!viewMode">(Type any string to search)</small></label>
                    <v-select
                      id="trade_name"
                      v-model="selectedSupplier"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :options="suppliers"
                      label="trade_name"
                      :disabled="isBusy || viewMode"
                      @search="onSearchSupplier"
                    >
                      <template #option="{ trade_name, tin }">
                        <strong>{{ trade_name }}</strong><br>
                        <span>{{ tin }}</span>
                      </template>
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="((viewMode && paymentRequest.supplier_type !== null) || (!viewMode && parseInt(paymentRequest.supplier_manual_input) ===1)) && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="supplier type"
                  vid="supplier_type"
                  rules="required"
                >
                  <b-form-group>
                    <label for="supplier_type">Supplier Type</label>
                    <b-form-select
                      id="supplier_type"
                      v-model="paymentRequest.supplier_type"
                      :options="supplierTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || viewMode"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Supplier Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="((viewMode && paymentRequest.supplier_type !== null) || !viewMode) && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="supplier trade name"
                  vid="trade_name"
                  :rules="supplierRuleOne"
                >
                  <b-form-group>
                    <label for="manual_trade_name">Supplier Trade Name</label>
                    <b-input
                      id="manual_trade_name"
                      v-model="paymentRequest.trade_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || viewMode || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.supplier_type === 'Corporation' && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="supplier company name"
                  vid="company_name"
                  :rules="supplierRuleOne"
                >
                  <b-form-group>
                    <label for="manual_company_name">Supplier Company Name</label>
                    <b-input
                      id="manual_company_name"
                      v-model="paymentRequest.company_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || viewMode || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="proprietor first name"
                  vid="proprietor_first_name"
                  :rules="supplierRuleTwo"
                >
                  <b-form-group>
                    <label for="manual_proprietor_first_name">Proprietor First Name</label>
                    <b-input
                      id="manual_proprietor_first_name"
                      v-model="paymentRequest.proprietor_first_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || viewMode || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="proprietor middle name"
                  vid="proprietor_middle_name"
                  :rules="supplierRuleTwo"
                >
                  <b-form-group>
                    <label for="manual_proprietor_middle_name">Proprietor Middle Name</label>
                    <b-input
                      id="manual_proprietor_middle_name"
                      v-model="paymentRequest.proprietor_middle_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || viewMode || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="proprietor last name"
                  vid="proprietor_last_name"
                  :rules="supplierRuleTwo"
                >
                  <b-form-group>
                    <label for="manual_proprietor_last_name">Proprietor Last Name</label>
                    <b-input
                      id="manual_proprietor_last_name"
                      v-model="paymentRequest.proprietor_last_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || viewMode || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="((viewMode && paymentRequest.supplier_type !== null) || !viewMode) && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="supplier tin"
                  vid="tin"
                  :rules="supplierRuleThree"
                >
                  <b-form-group>
                    <label for="manual_tin">Supplier Tin</label>
                    <b-input
                      id="manual_tin"
                      v-model="paymentRequest.tin"
                      v-mask="'###-###-###-####'"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || viewMode || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  v-if="((viewMode && paymentRequest.supplier_type !== null) || !viewMode) && paymentRequest.transaction_type === 'With 2307'"
                  #default="{ errors }"
                  name="supplier address"
                  vid="address"
                  :rules="supplierRuleTwo"
                >
                  <b-form-group>
                    <label for="manual_address">Supplier Address</label>
                    <b-textarea
                      id="manual_address"
                      v-model="paymentRequest.address"
                      rows="6"
                      max-rows="6"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || viewMode || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-tab> -->

              <b-tab
                title="Attachments"
              >
                <b-row v-if="!viewMode">
                  <b-col cols="12">
                    <b-form-file
                      v-model="files"
                      accept=".jpg, .jpeg, .png"
                      multiple
                      class="mb-1"
                      :disabled="isBusy || (parseInt(allowSubmit) === 1 && !hasFiles)"
                    />
                  </b-col>

                  <b-col cols="12">
                    <b-button
                      variant="success"
                      :disabled="isUploading || files.length < 1 || (parseInt(allowSubmit) === 1 && !hasFiles)"
                      class="mr-1"
                      @click="onUploadAttachment"
                    >
                      {{ isUploading ? 'Uploading Files...' : 'Upload Files' }}
                    </b-button>
                    <b-button
                      variant="danger"
                      :disabled="paymentRequest.file_list.length < 1"
                      @click="onRemoveAllFile"
                    >
                      {{ isRemoving ? 'Removing Files...' : 'Remove Files' }}
                    </b-button>
                  </b-col>
                </b-row>

                <b-button
                  v-if="viewMode && paymentRequest.file_list.length>0"
                  size="sm"
                  variant="success"
                  class="mb-2"
                  @click="onPreviewAllAttachment"
                >
                  Preview All
                </b-button>

                <b-table
                  v-if="paymentRequest.file_list.length > 0 || viewMode"
                  class="mt-1"
                  :fields="attachmentTableSettings.fields"
                  :items="paymentRequest.file_list"
                  responsive
                  striped
                  hover
                >
                  <template #cell(actions)="row">
                    <div class="text-nowrap">
                      <b-button
                        size="sm"
                        class="mr-1"
                        :disabled="isPreviewing || isRemovingFile"
                        @click="onPreviewAttachment(row.item)"
                      >
                        Preview
                      </b-button>
                      <b-button
                        v-if="!viewMode"
                        size="sm"
                        :disabled="isPreviewing || isRemovingFile"
                        @click="onRemoveFile(row.item)"
                      >
                        Remove
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <div
                  v-if="!hasFiles && !viewMode"
                  class="form-group mt-5"
                >
                  <b-form-checkbox
                    id="submit_proceed"
                    v-model="allowSubmit"
                    name="submit_proceed"
                    value="1"
                    unchecked-value="0"
                    :disabled="isBusy || isUploading || isRemoving || isRemovingFile"
                  >
                    Allow me to submit this request without attachment.
                  </b-form-checkbox>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          v-if="!viewMode"
          variant="success"
          :disabled="isBusy || parseInt(allowSubmit) === 0"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          {{ viewMode ? 'Close Window' : 'Cancel' }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-cancel-request-form"
      scrollable
      no-close-on-backdrop
      title="Cancel Request"
      @ok="onSubmitCancelForm"
    >
      <ValidationObserver
        ref="form2"
      >
        <form
          novalidate
          role="form2"
        >
          <ValidationProvider
            #default="{ errors }"
            name="cancellation reason"
            vid="cancellation_reason"
            rules="required|max:200"
          >
            <div class="form-group">
              <label for="cancellation_reason">Cancellation Reason</label>
              <b-form-textarea
                id="cancellation_reason"
                v-model="cancellationRequest.cancellation_reason"
                rows="6"
                max-rows="6"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter cancellation reason"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <div
            class="form-group"
          >
            <b-form-checkbox
              id="cancel_proceed"
              v-model="cancellationRequest.proceed"
              name="cancel_proceed"
              value="1"
              unchecked-value="0"
              :disabled="isBusy"
            >
              YES, IM SURE I WANT TO CANCEL THIS REQUEST
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="isBusy || parseInt(cancellationRequest.proceed) === 0"
          @click="ok()"
        >
          Proceed
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-pdf"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <vue-pdf-embed :source="preview" />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <b-img
        fluid
        :src="preview"
      />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-all-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachments`"
      size="lg"
    >
      <div>
        <b-img
          v-for="attachment in allAttachments"
          :key="attachment"
          fluid
          :src="attachment"
        />
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { AxiosError, UserMyRequestService, UserAttachmentService, UserActionService, SharedListService, SharedSearchService } from '@/services'
import _ from 'lodash'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  decimalLimit: 2
})

export default {
  name: 'UserMyRequests',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'My Requests'
    }
  },

  components: {
    VuePdfEmbed
  },

  mixins: [formatter, url],

  data () {
    return {
      viewMode: false,
      isBusy: false,
      isUploading: false,
      isRemoving: false,
      isRemovingFile: false,
      isPreviewing: false,
      hasFiles: false,
      allowSubmit: 0,
      employees: [],
      budgets: [],
      budgetActuals: [],
      itemDescriptions: [],
      suppliers: [],
      selectedBudget: null,
      selectedBudgetActual: null,
      selectedItemDescription: null,
      selectedNotedBy: null,
      selectedApprovedBy: null,
      selectedSupplier: null,
      files: [],
      preview: null,
      previewFilename: '',
      allAttachments: [],
      requestTypes: [
        { text: 'With 2307', value: 'With 2307' },
        { text: 'Without 2307', value: 'Without 2307' }
      ],
      paymentModes: [
        { text: 'Cash', value: 'Cash' },
        { text: 'Check', value: 'Check' },
        { text: 'Online Banking', value: 'Online Banking' },
        { text: 'Credit Card', value: 'Credit Card' }
      ],
      paymentActions: [
        { text: 'Pick Up', value: 'Pick Up' },
        { text: 'Deposit', value: 'Deposit' }
      ],
      paymentNatures: [
        { text: 'Services', value: 'Services' },
        { text: 'Goods', value: 'Goods' },
        { text: 'Rentals', value: 'Rentals' },
        { text: 'Professional Fee', value: 'Professional Fee' },
        { text: 'Blank 2307', value: 'Blank 2307' }
      ],
      documentTypes: [
        { text: 'Charge Invoice', value: 'Charge Invoice' },
        { text: 'Offical Receipt', value: 'Official Receipt' },
        { text: 'Sales Invoice', value: 'Sales Invoice' },
        { text: 'Cash Sales Invoice', value: 'Cash Sales Invoice' },
        { text: 'Cash Invoice', value: 'Cash Invoice' },
        { text: 'Payment Voucher', value: 'Payment Voucher' },
        { text: 'Blank 2307', value: 'Blank 2307' }
      ],
      receiptTypes: [
        { text: 'Vat-Reg', value: 'Vat-Reg' },
        { text: 'Non-Vat', value: 'Non-Vat' },
        { text: 'Blank 2307', value: 'Blank 2307' }
      ],
      supplierTypes: [
        { text: 'Sole Proprietorship', value: 'Sole Proprietorship' },
        { text: 'Corporation', value: 'Corporation' }
      ],
      paymentRequest: {
        id: 0,
        created_at: '',
        request_payee: '',
        request_amount: 0,
        request_status: '',
        transaction_type: null,
        budget_id: '',
        budget_actual_id: '',
        item_description_id: '',
        request_description: '',
        payment_mode: null,
        payment_action: null,
        bank: '',
        bank_account_number: '',
        bank_account_name: '',
        has_approval_one: 1,
        has_approval_two: 0,
        approval_one_id: '',
        approval_one_name: null,
        approval_one_at: null,
        approval_one_remarks: null,
        approval_two_id: '',
        approval_two_name: null,
        approval_two_at: null,
        approval_two_remarks: null,
        invoice_amount: 0,
        payment_nature: null,
        document_type: null,
        receipt_type: null,
        supplier_manual_input: 0,
        supplier_type: null,
        supplier_id: 0,
        trade_name: '',
        company_name: '',
        proprietor_first_name: '',
        proprietor_middle_name: '',
        proprietor_last_name: '',
        tin: '',
        address: '',
        file_path: '',
        file_list: []
      },
      cancellationRequest: {
        id: 0,
        cancellation_reason: '',
        proceed: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'request date', sortable: true },
          { key: 'request_payee' },
          { key: 'request_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'request_status' },
          { key: 'document_number' },
          { key: 'pending_approver' },
          { key: 'pending_approver_type', label: 'approver type' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      currencyMask
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    modalTitle () {
      return this.viewMode ? 'View Request' : 'New Request'
    },

    supplierRuleOne () {
      if (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307') {
        return ''
      }
      return 'required|max:200'
    },

    supplierRuleTwo () {
      if (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307') {
        return ''
      }
      return 'required|max:50'
    },

    supplierRuleThree () {
      if (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307') {
        return ''
      }
      return 'required|min:16|max:16'
    },

    notedByRequired () {
      if (this.user?.is_manager === 0) {
        return 'required'
      } else {
        return ''
      }
    }
  },

  watch: {
    'selectedBudget' (value) {
      if (!this.viewMode) {
        if (value) {
          this.paymentRequest.budget_id = value.id
          this.selectedItemDescription = null
          this.fetchItemDescriptionList(value.id)
        } else {
          this.selectedItemDescription = null
          this.paymentRequest.budget_id = ''
        }
      }
    },

    'selectedBudgetActual' (value) {
      if (value) {
        this.paymentRequest.budget_actual_id = value.id
      } else {
        this.paymentRequest.budget_actual_id = ''
      }
    },

    'selectedItemDescription' (value) {
      if (value) {
        this.paymentRequest.item_description_id = value.id
      } else {
        this.paymentRequest.item_description_id = ''
      }
    },

    'selectedNotedBy' (value) {
      if (value) {
        this.paymentRequest.approval_one_id = value.id
      } else {
        this.paymentRequest.approval_one_id = ''
      }
    },

    'selectedApprovedBy' (value) {
      if (value) {
        this.paymentRequest.approval_two_id = value.id
      } else {
        this.paymentRequest.approval_two_id = ''
      }
    },

    'selectedSupplier' (value) {
      if (value) {
        this.paymentRequest.supplier_id = value.id
        this.paymentRequest.supplier_type = value.supplier_type
        this.paymentRequest.trade_name = value.trade_name
        this.paymentRequest.company_name = value.company_name
        this.paymentRequest.proprietor_first_name = value.proprietor_first_name
        this.paymentRequest.proprietor_middle_name = value.proprietor_middle_name
        this.paymentRequest.proprietor_last_name = value.proprietor_last_name
        this.paymentRequest.tin = value.tin
        this.paymentRequest.address = value.address
      } else {
        this.paymentRequest.supplier_id = 0
        this.paymentRequest.supplier_type = !this.viewMode ? 'Sole Proprietorship' : null
        this.paymentRequest.trade_name = ''
        this.paymentRequest.company_name = ''
        this.paymentRequest.proprietor_first_name = ''
        this.paymentRequest.proprietor_middle_name = ''
        this.paymentRequest.proprietor_last_name = ''
        this.paymentRequest.tin = ''
        this.paymentRequest.address = ''
      }
    },

    'paymentRequest.payment_mode' (value) {
      if (!this.viewMode && value) {
        this.paymentRequest.payment_action = null
      }
    },

    'paymentRequest.supplier_manual_input' (value) {
      if (!this.viewMode && parseInt(value) === 1) {
        this.selectedSupplier = null
        this.paymentRequest.supplier_type = 'Sole Proprietorship'
        this.paymentRequest.supplier_id = 0
        this.paymentRequest.trade_name = ''
        this.paymentRequest.company_name = ''
        this.paymentRequest.proprietor_first_name = ''
        this.paymentRequest.proprietor_middle_name = ''
        this.paymentRequest.proprietor_last_name = ''
        this.paymentRequest.tin = ''
        this.paymentRequest.address = ''
      }
    },

    'paymentRequest.transaction_type' (value) {
      if (!this.viewMode && value) {
        this.paymentRequest.payment_nature = null
        this.paymentRequest.document_type = null
        this.paymentRequest.receipt_type = null
        this.paymentRequest.supplier_manual_input = 0
        this.selectedSupplier = null
        this.paymentRequest.supplier_id = 0
        this.paymentRequest.supplier_type = 'Sole Proprietorship'
        this.paymentRequest.trade_name = ''
        this.paymentRequest.company_name = ''
        this.paymentRequest.proprietor_first_name = ''
        this.paymentRequest.proprietor_middle_name = ''
        this.paymentRequest.proprietor_last_name = ''
        this.paymentRequest.tin = ''
        this.paymentRequest.address = ''
      }
    },

    'paymentRequest.supplier_type' (value) {
      if (!this.viewMode) {
        if (value === 'Corporation') {
          this.paymentRequest.proprietor_first_name = ''
          this.paymentRequest.proprietor_middle_name = ''
          this.paymentRequest.proprietor_last_name = ''
        }

        if (value === 'Sole Proprietorship') {
          this.paymentRequest.proprietor_first_name = ''
          this.paymentRequest.proprietor_middle_name = ''
          this.paymentRequest.proprietor_last_name = ''
        }
      }
    },

    'hasFiles' (value) {
      if (value) {
        this.allowSubmit = 1
      } else {
        this.allowSubmit = 0
      }
    },

    'paymentRequest.file_list' (value) {
      if (value.length > 0) {
        this.hasFiles = true
      } else {
        this.hasFiles = false
      }
    },

    'paymentRequest.has_approval_one' (value) {
      if (parseInt(value) === 0) {
        this.paymentRequest.approval_one_id = null
        this.selectedNotedBy = null
      }
    }
  },

  mounted () {
    core.index()
    this.fetchEmployeeList()
    this.fetchBudgetList()
    this.fetchBudgetActualList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserMyRequestService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async fetchEmployeeList () {
      await SharedListService.getEmployeeList().then(({ data }) => {
        this.employees = data
      })
    },

    async fetchBudgetList () {
      await SharedListService.getBudgetList().then(({ data }) => {
        this.budgets = data
      })
    },

    async fetchBudgetActualList () {
      await SharedListService.getBudgetActualList().then(({ data }) => {
        this.budgetActuals = data
      })
    },

    async fetchItemDescriptionList (budgetId) {
      await SharedListService.getItemDescriptionList(budgetId).then(({ data }) => {
        this.itemDescriptions = data
      })
    },

    onSearchSupplier (search, loading) {
      if (search.length) {
        loading(true)
        this.searchSupplier(search, loading, this)
      }
    },

    searchSupplier: _.debounce(async (search, loading, vm) => {
      await SharedSearchService.getSupplier(`search_string=${search}`).then(({ data }) => {
        loading(false)
        vm.suppliers = data
      }).catch(() => {
        loading(false)
      })
    }, 1000),

    onShowModalAddForm () {
      this.files = []
      this.itemDescriptions = []
      this.viewMode = false
      this.allowSubmit = 0
      this.paymentRequest.id = 0
      this.paymentRequest.request_payee = ''
      this.paymentRequest.request_amount = '0.00'
      this.paymentRequest.transaction_type = 'Without 2307'
      this.paymentRequest.budget_id = ''
      this.paymentRequest.budget_actual_id = ''
      this.paymentRequest.item_description_id = ''
      this.paymentRequest.request_description = ''
      this.paymentRequest.payment_mode = null
      this.paymentRequest.payment_action = null
      this.paymentRequest.bank = ''
      this.paymentRequest.bank_account_number = ''
      this.paymentRequest.bank_account_name = ''
      this.paymentRequest.invoice_amount = '0.00'
      this.paymentRequest.supplier_manual_input = 0
      this.paymentRequest.supplier_id = 0
      this.paymentRequest.supplier_type = 'Sole Proprietorship'
      this.paymentRequest.trade_name = ''
      this.paymentRequest.company_name = ''
      this.paymentRequest.proprietor_first_name = ''
      this.paymentRequest.proprietor_middle_name = ''
      this.paymentRequest.proprietor_last_name = ''
      this.paymentRequest.tin = ''
      this.paymentRequest.address = ''
      this.paymentRequest.payment_nature = null
      this.paymentRequest.document_type = null
      this.paymentRequest.receipt_type = null
      this.selectedBudget = null
      this.selectedBudgetActual = null
      this.selectedItemDescription = null
      this.selectedSupplier = null
      this.selectedNotedBy = null
      this.selectedApprovedBy = null
      this.paymentRequest.file_path = ''
      this.paymentRequest.file_list = []
      // this.paymentRequest.has_approval_one = 1
      this.$bvModal.show('modal-payment-request-form')
    },

    onShowModalCancelForm (item) {
      this.cancellationRequest.id = item.id
      this.cancellationRequest.cancellation_reason = ''
      this.cancellationRequest.proceed = false
      this.$bvModal.show('modal-cancel-request-form')
    },

    onShowModalViewForm (item) {
      this.viewMode = true
      this.paymentRequest.id = item.id
      this.paymentRequest.created_at = this.dateTimeFormatter(item.created_at)
      this.paymentRequest.request_payee = item.request_payee
      this.paymentRequest.request_amount = item.request_amount
      this.paymentRequest.transaction_type = item.transaction_type
      this.paymentRequest.request_status = item.request_status
      this.paymentRequest.budget_id = item.budget_id
      this.paymentRequest.budget_actual_id = item.budget_actual_id
      this.paymentRequest.item_description_id = item.item_description_id
      this.paymentRequest.request_description = item.request_description
      this.paymentRequest.payment_mode = item.payment_mode
      this.paymentRequest.payment_action = item.payment_action
      this.paymentRequest.bank = item.bank
      this.paymentRequest.bank_account_number = item.bank_account_number
      this.paymentRequest.bank_account_name = item.bank_account_name
      this.paymentRequest.request_description = item.request_description
      this.paymentRequest.approval_one_name = item.approval_one_name
      this.paymentRequest.approval_one_status = item.approval_one_status
      this.paymentRequest.approval_one_at = item.approval_one_at
      this.paymentRequest.approval_one_remarks = item.approval_one_remarks
      this.paymentRequest.approval_two_name = item.approval_two_name
      this.paymentRequest.approval_two_status = item.approval_two_status
      this.paymentRequest.approval_two_at = item.approval_two_at
      this.paymentRequest.approval_two_remarks = item.approval_two_remarks
      this.paymentRequest.invoice_amount = item.invoice_amount
      this.paymentRequest.supplier_id = item.supplier_id === null ? 0 : item.supplier_id
      this.paymentRequest.supplier_type = item.supplier_type
      this.paymentRequest.trade_name = item.trade_name
      this.paymentRequest.company_name = item.company_name
      this.paymentRequest.proprietor_first_name = item.proprietor_first_name
      this.paymentRequest.proprietor_middle_name = item.proprietor_middle_name
      this.paymentRequest.proprietor_last_name = item.proprietor_last_name
      this.paymentRequest.tin = item.tin
      this.paymentRequest.address = item.address
      this.paymentRequest.payment_nature = item.payment_nature
      this.paymentRequest.document_type = item.document_type
      this.paymentRequest.receipt_type = item.receipt_type
      this.paymentRequest.file_path = item.file_path
      this.paymentRequest.file_list = item.attachments
      this.selectedBudget = { id: item.budget_id, budget_name: item.budget_name }
      this.selectedBudgetActual = { id: item.budget_actual_id, actual_name: item.budget_actual_name }
      this.selectedItemDescription = { id: item.item_description_id, item_name: item.item_name }
      this.selectedNotedBy = { id: item.approval_one_id, employee_name: item.approval_one_name }
      this.selectedApprovedBy = { id: item.approval_two_id, employee_name: item.approval_two_name }
      this.selectedSupplier = {
        supplier_type: item.supplier_type,
        supplier_id: item.supplier_id,
        trade_name: item.trade_name,
        company_name: item.company_name,
        proprietor_first_name: item.proprietor_first_name,
        proprietor_middle_name: item.proprietor_middle_name,
        proprietor_last_name: item.proprietor_last_name,
        tin: item.tin,
        address: item.address
      }
      this.$bvModal.show('modal-payment-request-form')
    },

    async onConfirmFormPostSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Do you really want to submit request?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise((resolve, reject) => {
        this.isBusy = true
        if (this.paymentRequest.tin === '' || this.paymentRequest.tin === null) {
          this.paymentRequest.supplier_type = null
        }
        if (this.user?.is_manager === 1) {
          if (this.selectedNotedBy === null) {
            this.paymentRequest.has_approval_one = 0
          }
        }
        UserMyRequestService.post(this.paymentRequest).then(response => {
          this.$bvModal.hide('modal-payment-request-form')
          this.isBusy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.isBusy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger'
              })
              resolve(error)
            }
          }
        })
      })
    },

    async onSubmitCancelForm (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form2.validate().then(async success => {
        if (success) {
          this.isBusy = true
          UserActionService.postRequestCancellation(this.cancellationRequest).then(response => {
            this.$bvModal.hide('modal-cancel-request-form')
            this.isBusy = false
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: response.data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }).catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
                  title: 'Validation Error',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'danger'
                })
              }
            }
            this.isBusy = false
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    async onUploadAttachment () {
      const formBody = new FormData()

      let counter = 0

      this.files.forEach(file => {
        formBody.append(`file[${counter}]`, file)
        counter++
      })

      formBody.append('file_path', this.paymentRequest.file_path)

      this.isUploading = true

      await UserAttachmentService.upload(formBody).then(response => {
        this.paymentRequest.file_path = response.data.file_path
        this.paymentRequest.file_list.push(...response.data.file_list)
        this.isUploading = false
        this.files = []
      }).catch(() => {
        this.files = []
        this.isUploading = false
      })
    },

    async onRemoveFile (file) {
      this.isRemovingFile = true

      await UserAttachmentService.removeFile(this.paymentRequest.file_path, file.file_hash_name).then(() => {
        this.paymentRequest.file_list = _.reject(this.paymentRequest.file_list, currentObject => {
          return currentObject.file_hash_name === file.file_hash_name
        })
        this.isRemovingFile = false
      }).catch(() => {
        this.isRemovingFile = false
      })
    },

    async onRemoveAllFile () {
      this.isRemoving = true

      await UserAttachmentService.removeAllFile(this.paymentRequest.file_path).then(() => {
        this.paymentRequest.file_path = ''
        this.paymentRequest.file_list = []
        this.isRemoving = false
      }).catch(() => {
        this.files = []
        this.isRemoving = false
      })
    },

    async onPreviewAttachment (item) {
      if (this.viewMode) {
        this.previewFilename = item.file_original_name
        this.preview = this.getImageUrl(`${this.paymentRequest.file_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      } else {
        this.previewFilename = item.file_original_name
        this.preview = this.getImageUrl(`uploads/${this.paymentRequest.file_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      }
    },

    onPreviewAllAttachment () {
      this.allAttachments = []

      this.paymentRequest.file_list.forEach(file => {
        this.allAttachments.push(this.getImageUrl(`${this.paymentRequest.file_path}/${file.file_hash_name}`))
      })

      this.$bvModal.show('modal-preview-all-image')
    }
  }
}
</script>
